import React from "react"
import Layout from "../components/LayoutComponent"
import Seo from "../components/SeoComponent"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import "../assets/services.css"

const Services = () => {
  const { backgroundImage } = useStaticQuery(graphql`
    query {
      backgroundImage: file(
        relativePath: { eq: "LANDSCAPE_SERVICES_AND_DISCOUNTS.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `)
  const pluginImage = getImage(backgroundImage)
  const intl = useIntl()
  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "services" })}
        canonicalUrl={"services-and-discount"}
      />
      <BgImage image={pluginImage} className="bg-services"></BgImage>
      <article className="text-services">
        <h1>
          <FormattedMessage id="service.service-h1" />
        </h1>
        <p>
          <FormattedMessage id="service.service-one" />
        </p>
        <p>
          <FormattedMessage id="service.service-two" />
        </p>
        <p>
          <FormattedMessage id="service.service-three" />
        </p>
      </article>
    </Layout>
  )
}

export default Services
